<template>
  <div
    class="a-info-modal"
    :class="[
      { 'a-info-modal--scroll-down': shouldScroll === true },
      { 'a-info-modal--scroll-up': shouldScroll === false }
    ]"
  >
    <div class="a-info-modal__wrapper">
      <div class="a-info-modal__wrapper__content">
        <span class="a-info-modal__wrapper__content__title">
          <i><IconWarning fill="#00305a" :width="24" :height="24"/></i>
          <strong>ATENÇÃO!</strong>
        </span>

        <p class="a-info-modal__wrapper__content__text">
          <span>
            A Alfa não solicita depósito antecipado para efetivar operações financeiras e não operamos
            com Chave PIX (CNPJ / e-mail / Celular) para pagamento de parcelas de empréstimos e financiamentos, ainda
            que vencidos.
          </span>
        </p>
      </div>

      <button
        class="a-info-modal__wrapper__close"
        type="button"
        @click="handleCloseButton"
      >
        <IconCancelCircle fill="#00c5d7" :width="24" :height="24" />
      </button>
    </div>
  </div>
</template>

<script setup>
// Dependências
import { storeToRefs } from 'pinia'
import { useSettingsStore } from '~/stores/settings'

// Icones
import IconWarning from '@/assets/icons/IconWarning.vue'
import IconCancelCircle from '@/assets/icons/IconCancelCircle.vue'

// Pinia
const settingsStore = useSettingsStore()
const { infoSettings } = storeToRefs(settingsStore)
const { setInfoSettings } = settingsStore

// Variáveis
const shouldScroll = ref(null)
shouldScroll.value = infoSettings.value.hasActiveModal === null ? true : null

// Métodos
function handleCloseButton() {
  shouldScroll.value = false
  setInfoSettings({ hasActiveModal: false })
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.a-info-modal {
  pointer-events: none;
  opacity: 0;
  z-index: 996;
  position: fixed;
  display: grid;
  place-items: start stretch;
  width: 100svw;
  padding: 12px;

  @media(hover: hover) {
    width: calc(100svw - 15px);
  }

  &--scroll-down {
    pointer-events: all;
    animation: scroll-down 700ms cubic-bezier(0.45, 0.05, 0.55, 0.95) 3s forwards;
  }

  &--scroll-up {
    animation: scroll-up 700ms cubic-bezier(0.45, 0.05, 0.55, 0.95) forwards;
  }

  &__wrapper {
    overflow: hidden;
    position: relative;
    display: grid;
    place-content: center;
    column-gap: 16px;
    padding: 32px;
    border-radius: 10px;
    background-color: $alfa-support-1;
    @include box-shadow(#00468233, 0, 3px, 4px);

    @media screen and (max-width: 768px) {
      padding: 24px;
      column-gap: 0;
    }

    &__content {
      display: grid;
      grid-template-columns: auto 1fr;
      place-content: center start;
      column-gap: 24px;

      @media screen and (max-width: 768px)  {
        grid-template-columns: 1fr;
        row-gap: 16px;
      }

      &__title {
        display: grid;
        grid-auto-flow: column;
        place-content: center;
        place-items: center;
        column-gap: 20px;

        & > i {
          display: grid;
          place-content: center;
        }

        & > strong {
          @include font-custom(10, 16, center, 'Montserrat Bold');
        }
      }

      &__text {
        display: grid;
        place-content: start stretch;

        @media screen and (min-width: 768px)  {
          padding-right: 40px;
        }

        & > span {
          @include font-custom(10, 16, justify);
        }
      }
    }

    &__close {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      display: grid;
      place-content: center;
      background-color: unset;
      transition: transform 100ms linear;

      @media screen and (min-width: 768px)  {
        top: 50%;
        right: 24px;
        transform: translate(0, -50%);
      }

      &:active {
        transform: scale(.85);
      }

      @media screen and (max-width: 768px)  {
        align-self: start ;
      }
    }
  }
}

@keyframes scroll-down {
  0% {
    opacity: 0;
    top: -25%;
  } 60% {
    opacity: .5;
  } 100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes scroll-up {
  0% {
    opacity: 1;
    top: 0;
  } 40% {
    opacity: .5;
  } 100% {
    opacity: 0;
    top: -25%;
  }
}
</style>
