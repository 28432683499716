<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="30.303" height="28.917" viewBox="0 0 30.303 28.917">
        <path id="icon_estrela" d="M29.237,22.39a.857.857,0,0,0-.692-.583L19.468,20.49l-4.059-8.216a.858.858,0,0,0-1.538,0L9.811,20.49.735,21.807a.857.857,0,0,0-.475,1.462l6.568,6.4-1.55,9.03a.858.858,0,0,0,1.245.9l8.118-4.264L22.758,39.6a.858.858,0,0,0,1.245-.9l-1.551-9.03,6.568-6.4A.856.856,0,0,0,29.237,22.39Z" transform="translate(0.512 -11.296)" fill="none" :stroke="fill" stroke-width="1"/>
    </svg> 
</template>

<script>
export default {
    name: 'IconStar',
    props: {
        fill: {
            type: String,
            default: '#00c5d7'
        }
    }
}
</script>

<style>

</style>