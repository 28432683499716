<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Camada_1" viewBox="0 0 33.11 30.2">
    <g id="Graphic_Elements">
      <g id="Graphic_Elements-2">
        <g id="Grupo_4325">
          <circle id="Elipse_444" class="cls-1" cx="18.01" cy="15.1" r="3.2" :fill="fill" />
          <path
            id="Caminho_4174"
            class="cls-1"
            d="m25.41,9.5c-.3-.8-1-1.5-1.8-1.8-.6-.2-1.2-.3-1.8-.3-1,0-1.3-.1-3.8-.1s-2.8,0-3.8.1c-.6,0-1.2.1-1.8.3-.8.3-1.5,1-1.8,1.8-.2.6-.3,1.2-.3,1.8,0,1-.1,1.3-.1,3.8s0,2.8.1,3.8c0,.6.1,1.2.3,1.8.3.8,1,1.5,1.8,1.8.6.2,1.2.3,1.8.3,1,0,1.3.1,3.8.1s2.8,0,3.8-.1c.6,0,1.2-.1,1.8-.3.8-.3,1.5-1,1.8-1.8.2-.6.3-1.2.3-1.8,0-1,.1-1.3.1-3.8s0-2.8-.1-3.8c0-.6-.1-1.2-.3-1.8Zm-7.4,10.5c-2.7,0-4.9-2.2-4.9-4.9s2.2-4.9,4.9-4.9,4.9,2.2,4.9,4.9h0c0,2.7-2.2,4.9-4.9,4.9h0Zm5.1-8.8c-.6,0-1.1-.5-1.1-1.1s.5-1.1,1.1-1.1,1.1.5,1.1,1.1h0c0,.6-.5,1.1-1.1,1.1h0Z"
            :fill="fill"
          />
          <path
            id="Caminho_4175"
            class="cls-1"
            d="m18.01,0C9.71,0,2.91,6.8,2.91,15.1s6.8,15.1,15.1,15.1,15.1-6.8,15.1-15.1S26.41,0,18.01,0h0Zm9.5,19c0,.8-.2,1.6-.4,2.3-.5,1.3-1.5,2.3-2.8,2.8-.7.3-1.5.4-2.3.4-1,0-1.3.1-3.9.1s-2.9,0-3.9-.1c-.8,0-1.6-.2-2.3-.4-1.3-.5-2.3-1.5-2.8-2.8-.3-.7-.4-1.5-.4-2.3,0-1-.1-1.3-.1-3.9s0-2.9.1-3.9c0-.8.2-1.6.4-2.3.5-1.3,1.5-2.3,2.8-2.8.7-.3,1.5-.4,2.3-.4,1,0,1.3-.1,3.9-.1s2.9,0,3.9.1c.8,0,1.6.2,2.3.4,1.3.5,2.3,1.5,2.8,2.8.3.7.4,1.5.4,2.3,0,1,.1,1.3.1,3.9s-.1,2.9-.1,3.9Z"
            :fill="fill"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconInstagramFooter',
  props: {
    fill: {
      type: String,
      default: '#00c5d7',
    },
  },
}
</script>
