<template>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
  <path id="_--icon_ajuda" data-name="--icon_ajuda" d="M31,16A15,15,0,1,0,46,31,15.044,15.044,0,0,0,31,16Zm0,28.235A13.235,13.235,0,1,1,44.235,31,13.218,13.218,0,0,1,31,44.235Zm5.294-16.324a5.268,5.268,0,0,1-4.412,5.206v1.412a.882.882,0,1,1-1.765,0V32.324A.885.885,0,0,1,31,31.441a3.529,3.529,0,1,0-3.529-3.529.882.882,0,0,1-1.765,0,5.294,5.294,0,1,1,10.588,0Zm-4.368,9.221a1.27,1.27,0,0,1,.4.926,1.324,1.324,0,1,1-2.25-.926A1.361,1.361,0,0,1,31.926,37.132Z" transform="translate(-16 -16)" :fill="fill"/>
</svg>
</template>

<script>
export default {
    name: 'IconHelp',
    props: {
        fill: {
            type: String,
            default: '#00c5d7'
        }
    }
}
</script>