<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height"
    :viewBox="`0 0 45 45`">
    <g id="icon_menu_hamburguer" transform="translate(-330 -25)">
      <path id="Caminho_96" data-name="Caminho 96" d="M0,0H19.5" transform="translate(343 40)" :fill="fill" :stroke="fill"
        stroke-linecap="round" stroke-width="2" />
      <path id="Caminho_97" data-name="Caminho 97" d="M0,0H19.5" transform="translate(343 54)" :fill="fill" :stroke="fill"
        stroke-linecap="round" stroke-width="2" />
      <path id="Caminho_98" data-name="Caminho 98" d="M0,0H19.5" transform="translate(343 47)" :fill="fill" :stroke="fill"
        stroke-linecap="round" stroke-width="2" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconMenuHamburguer',
  props: {
    fill: { type: String, default: '#ffff' },
    width: { type: String, default: '55' },
    height: { type: String, default: '55' },
  },
}
</script>
