<template>
	<svg id="icon_fechar" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
		<path id="Caminho_425" data-name="Caminho 425" d="M10.5,19.688A9.188,9.188,0,1,0,1.313,10.5,9.187,9.187,0,0,0,10.5,19.688ZM10.5,21A10.5,10.5,0,1,0,0,10.5,10.5,10.5,0,0,0,10.5,21Z" fill="#ffffff" />
		<path id="Caminho_426" data-name="Caminho 426" d="M19.3,9.191a.656.656,0,0,1,0,.929l-9.18,9.18a.657.657,0,0,1-.929-.929l9.18-9.18a.656.656,0,0,1,.929,0Z" transform="translate(-3.745 -3.745)" fill="#a7b9c9" />
		<path id="Caminho_427" data-name="Caminho 427" d="M9.191,9.191a.656.656,0,0,0,0,.929l9.18,9.18a.657.657,0,0,0,.929-.929l-9.18-9.18a.656.656,0,0,0-.929,0Z" transform="translate(-3.745 -3.745)" fill="#a7b9c9" />
	</svg>
</template>
<script >
export default {
	name: 'IconClose',
};
</script>
