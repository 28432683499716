<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="30.888" height="29.395" viewBox="0 0 30.888 29.395">
    <g id="icon_inicio" transform="translate(-170.8 -100.8)">
        <path id="Caminho_1882" data-name="Caminho 1882" d="M171.766,114.8h12.066a.968.968,0,0,0,.965-.965V101.767a.968.968,0,0,0-.965-.965H171.766a.968.968,0,0,0-.965.965v12.066A.968.968,0,0,0,171.766,114.8Zm.428-12.625h11.322v11.286H172.194Z" transform="translate(-0.001 -0.002)" :fill="fill"/>
        <path id="Caminho_1898" data-name="Caminho 1898" d="M171.766,114.8h12.066a.968.968,0,0,0,.965-.965V101.767a.968.968,0,0,0-.965-.965H171.766a.968.968,0,0,0-.965.965v12.066A.968.968,0,0,0,171.766,114.8Zm.428-12.625h11.322v11.286H172.194Z" transform="translate(16.891 -0.002)" :fill="fill"/>
        <path id="Caminho_1899" data-name="Caminho 1899" d="M171.766,114.8h12.066a.968.968,0,0,0,.965-.965V101.767a.968.968,0,0,0-.965-.965H171.766a.968.968,0,0,0-.965.965v12.066A.968.968,0,0,0,171.766,114.8Zm.428-12.625h11.322v11.286H172.194Z" transform="translate(-0.001 15.397)" :fill="fill"/>
        <path id="Caminho_1900" data-name="Caminho 1900" d="M171.766,114.8h12.066a.968.968,0,0,0,.965-.965V101.767a.968.968,0,0,0-.965-.965H171.766a.968.968,0,0,0-.965.965v12.066A.968.968,0,0,0,171.766,114.8Zm.428-12.625h11.322v11.286H172.194Z" transform="translate(16.891 15.397)" :fill="fill"/>
    </g>
    </svg>
</template>

<script>
export default {
    name: 'IconHome',
    props: {
        fill: {
            type: String,
            default: '#00c5d7'
        }
    }
}
</script>

<style>

</style>