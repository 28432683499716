<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Camada_1" viewBox="0 0 33.59 30.2">
    <g id="Graphic_Elements">
      <g id="Graphic_Elements-2">
        <path
          id="Caminho_4179"
          class="cls-1"
          d="m15.11.01C6.81.01,0,6.81,0,15.11,0,22.51,5.41,28.91,12.71,30.01v-10.5h-3.8v-4.4h3.8v-3.3c0-3.8,2.3-5.9,5.7-5.9,1.1,0,2.3.1,3.4.3v3.7h-1.9c-1.2-.2-2.3.7-2.5,1.9v3.3h4.2l-.7,4.4h-3.5v10.6c8.2-1.3,13.9-9,12.6-17.3C28.91,5.41,22.51.01,15.11.01Z"
          :fill="fill"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconFacebookFooter',
  props: {
    fill: {
      type: String,
      default: '#00c5d7',
    },
  },
}
</script>
