<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.792" height="22.773" viewBox="0 0 22.792 22.773">
    <g id="_--icon_telefone" data-name="--icon_telefone" transform="translate(-0.637 -0.6)">
      <path id="Caminho_1765" data-name="Caminho 1765"
        d="M22.429,18l-4.455-2.97a1.3,1.3,0,0,0-1.741.283l-1.3,1.668a.555.555,0,0,1-.711.15l-.243-.136A14.915,14.915,0,0,1,10.1,13.936a14.976,14.976,0,0,1-3.053-3.888l-.135-.243a.555.555,0,0,1,.147-.714L8.721,7.8A1.3,1.3,0,0,0,9,6.055L6.034,1.6a1.3,1.3,0,0,0-1.743-.392L2.428,2.326a2.628,2.628,0,0,0-1.2,1.555c-.675,2.44-.17,6.657,6.043,12.871,4.941,4.941,8.622,6.271,11.151,6.271a6.5,6.5,0,0,0,1.724-.225A2.625,2.625,0,0,0,21.7,21.6l1.12-1.863A1.3,1.3,0,0,0,22.429,18Zm-.243,1.368-1.117,1.863a1.9,1.9,0,0,1-1.119.867c-2.256.62-6.2.106-12.162-5.858S1.308,6.329,1.93,4.073A1.9,1.9,0,0,1,2.8,2.949L4.664,1.832A.561.561,0,0,1,5.421,2L7.034,4.427,8.389,6.458a.567.567,0,0,1-.122.756L6.6,8.51a1.284,1.284,0,0,0-.34,1.648l.132.243a15.557,15.557,0,0,0,3.179,4.05,15.573,15.573,0,0,0,4.056,3.178l.243.133a1.284,1.284,0,0,0,1.648-.34l1.3-1.668a.567.567,0,0,1,.756-.122l4.455,2.97a.561.561,0,0,1,.167.761Z"
        :fill="fill" :stroke="fill" stroke-width="0.7" />
      <path id="Caminho_1766" data-name="Caminho 1766"
        d="M282.992,70.058a6.241,6.241,0,0,1,6.237,6.237.367.367,0,1,0,.733,0A6.975,6.975,0,0,0,283,69.329a.367.367,0,1,0,0,.733Z"
        transform="translate(-269.521 -65.371)" :fill="fill" />
      <path id="Caminho_1767" data-name="Caminho 1767"
        d="M282.991,121.257a4.039,4.039,0,0,1,4.034,4.034.367.367,0,1,0,.733,0,4.773,4.773,0,0,0-4.767-4.768.367.367,0,1,0,0,.733Z"
        transform="translate(-269.521 -114.369)" :fill="fill" />
      <path id="Caminho_1768" data-name="Caminho 1768"
        d="M283,172.457a1.835,1.835,0,0,1,1.834,1.834.367.367,0,1,0,.733,0A2.57,2.57,0,0,0,283,171.724a.367.367,0,0,0,0,.733Z"
        transform="translate(-269.529 -163.369)" :fill="fill" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPhone',
  props: {
    fill: {
      type: String,
      default: '#00c5d7'
    }
  }
}
</script>
