<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Camada_1" viewBox="0 0 36.55 30.2">
    <g id="Graphic_Elements">
      <g id="Graphic_Elements-2">
        <g id="Grupo_4326">
          <path id="Caminho_4176" class="cls-1" d="m15.67,18.1l5.2-3-5.2-3s0,6,0,6Z" :fill="fill" />
          <path
            id="Caminho_4177"
            class="cls-1"
            d="m17.67,0C9.37,0,2.57,6.8,2.57,15.1s6.8,15.1,15.1,15.1,15.1-6.8,15.1-15.1S26.07,0,17.67,0h0Zm9.7,20c-.2.9-.9,1.5-1.8,1.8-1.6.4-7.9.4-7.9.4,0,0-6.3,0-7.9-.4-.9-.2-1.5-.9-1.8-1.8-.6-3.2-.6-6.5,0-9.7.2-.9.9-1.5,1.8-1.8,1.6-.4,7.9-.4,7.9-.4,0,0,6.3,0,7.9.4.9.2,1.5.9,1.8,1.8.3,1.6.4,3.2.4,4.9,0,1.5-.2,3.2-.4,4.8Z"
            :fill="fill"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconYoutubeFooter',
  props: {
    fill: {
      type: String,
      default: '#00c5d7',
    },
  },
}
</script>
