<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="25.295" height="21.979" viewBox="0 0 25.295 21.979">
    <g id="_--icon_casa" data-name="--icon_casa" transform="translate(-1.07 -3)">
        <path id="Caminho_1762" data-name="Caminho 1762" d="M25.525,13.145a.845.845,0,0,1-.49-.161l-11.344-8.1-11.344,8.1a.845.845,0,0,1-.981-1.369L13.2,3.161a.845.845,0,0,1,.981,0l11.835,8.453a.845.845,0,0,1-.49,1.53Z" transform="translate(0 -0.001)" :fill="fill"/>
        <path id="Caminho_1763" data-name="Caminho 1763" d="M4.845,8.072A.845.845,0,0,1,4,7.227V3.845A.845.845,0,0,1,4.845,3H8.227a.845.845,0,1,1,0,1.691H5.691V7.227A.845.845,0,0,1,4.845,8.072Z" transform="translate(-0.453 0)" :fill="fill"/>
        <path id="Caminho_1764" data-name="Caminho 1764" d="M21.752,26.835H17.525a.845.845,0,0,1-.845-.845V20.072H11.608v5.917a.845.845,0,0,1-.845.845H6.536A2.536,2.536,0,0,1,4,24.3V15.845a.845.845,0,1,1,1.691,0V24.3a.845.845,0,0,0,.845.845H9.917V19.227a.845.845,0,0,1,.845-.845h6.763a.845.845,0,0,1,.845.845v5.917h3.381A.845.845,0,0,0,22.6,24.3V15.845a.845.845,0,1,1,1.691,0V24.3a2.536,2.536,0,0,1-2.536,2.536Z" transform="translate(-0.453 -1.856)" :fill="fill"/>
    </g>
</svg>
</template>

<script>
export default {
    name: 'IconHouse',
    props: {
        fill: {
            type: String,
            default: '#00c5d7'
        }
    }
}
</script>