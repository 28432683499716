<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="31.677" height="31.677" viewBox="0 0 31.677 31.677">
        <path id="_--icon_perfil" data-name="--icon_perfil" d="M15.8,0A15.839,15.839,0,1,1,0,15.8,15.839,15.839,0,0,1,15.8,0ZM6.115,26.327a10.833,10.833,0,0,1,6.709-5.945A6.036,6.036,0,0,1,9.766,15.2v-.51a6.03,6.03,0,1,1,12.059,0v.51a5.959,5.959,0,0,1-2.972,5.18,10.48,10.48,0,0,1,6.624,5.945A14.422,14.422,0,0,0,30.064,15.8a14.267,14.267,0,1,0-28.535,0A14.422,14.422,0,0,0,6.115,26.327Zm18.174,1.019A9.082,9.082,0,0,0,15.8,21.571a8.968,8.968,0,0,0-8.408,5.775A14.4,14.4,0,0,0,15.8,30.064,14.664,14.664,0,0,0,24.289,27.346ZM15.8,10.191a4.531,4.531,0,0,0-4.5,4.5v.51a4.5,4.5,0,1,0,9,0v-.51A4.531,4.531,0,0,0,15.8,10.191Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'IconProfile',
    props: {
        fill: {
            type: String,
            default: '#00c5d7'
        }
    }
}
</script>

<style>

</style>