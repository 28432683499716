<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Camada_1" viewBox="0 0 36.69 30.2">
    <g id="Graphic_Elements">
      <g id="Graphic_Elements-2">
        <path
          id="Caminho_4178"
          class="cls-1"
          d="m17.67.01C9.37.01,2.57,6.81,2.57,15.11s6.8,15.1,15.1,15.1,15.1-6.8,15.1-15.1c0-8.3-6.7-15.1-15.1-15.1h0Zm-5,23.4h-3.2q-.2,0-.2-.2v-11.5q0-.2.2-.2h3.3c.2,0,.2.1.2.3v11.4c0,.1-.1.2-.3.2h0Zm-1.6-13.6c-1.2,0-2.1-1-2.1-2.1,0-1.2,1-2.1,2.1-2.1,1.2,0,2.1,1,2.1,2.1.1,1.1-.9,2.1-2.1,2.1h0Zm15.8,13.3c0,.2-.1.2-.2.2h-3.2c-.2,0-.2-.1-.2-.2v-6c0-.5,0-1-.2-1.5-.2-.8-1-1.4-1.8-1.3-1.1-.1-2,.8-2.1,1.9v.1c0,.3-.1.6-.1.9v5.8c0,.2,0,.3-.2.2h-3.2q-.2,0-.2-.2v-11.5c0-.2.1-.2.2-.2h3.1c.2,0,.2.1.2.2v1.4c.2-.3.5-.6.7-.8.7-.7,1.8-1.1,2.8-1.1.6,0,1.2.1,1.8.2,1.2.3,2.2,1.3,2.4,2.6.2,1,.4,1.9.3,2.9-.1,2.2-.1,4.3-.1,6.4h0Z"
          :fill="fill"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconLinkedinFooter',
  props: {
    fill: {
      type: String,
      default: '#00c5d7',
    },
  },
}
</script>
