<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="75.229" height="29.978" viewBox="0 0 75.229 29.978">
        <g id="_--icon_logo-consignadobranco" data-name="--icon_logo-consignadobranco" transform="translate(-23.4 -36)">
            <path id="Caminho_1659" data-name="Caminho 1659" d="M400.326,146.731c-.089,0-.126.036-.161.144l-.972,2.569a3.5,3.5,0,0,0-.27,1.1c0,.324.162.557.719.557h.27c.215,0,.269.036.269.144,0,.144-.108.18-.305.18-.575,0-1.348-.054-1.9-.054-.194,0-1.186.054-2.12.054-.234,0-.341-.036-.341-.18,0-.108.072-.144.216-.144a5.377,5.377,0,0,0,.557-.036c.827-.108,1.166-.719,1.527-1.62l4.51-11.283c.216-.518.324-.719.5-.719.161,0,.27.161.449.575.431.988,3.3,8.318,4.456,11.05.682,1.62,1.2,1.869,1.581,1.959a3.946,3.946,0,0,0,.754.071c.144,0,.234.018.234.144,0,.144-.162.18-.827.18-.648,0-1.958,0-3.4-.036-.324-.018-.539-.018-.539-.144,0-.108.072-.144.251-.161a.293.293,0,0,0,.161-.413l-1.437-3.809a.173.173,0,0,0-.18-.126Zm3.665-.9c.09,0,.108-.054.09-.108l-1.62-4.438c-.018-.072-.036-.162-.09-.162s-.089.09-.108.162l-1.65,4.416c-.018.072,0,.126.072.126Z" transform="translate(-347.999 -94.867)" fill="#fff"/>
            <path id="Caminho_1660" data-name="Caminho 1660" d="M630.481,151.962c0,2.3,0,3.54.378,3.863.305.27.772.4,2.174.4.952,0,1.653-.018,2.1-.5a2.246,2.246,0,0,0,.467-1.078c.018-.162.054-.27.2-.27.126,0,.144.09.144.305a14.224,14.224,0,0,1-.27,2.066c-.108.4-.194.485-1.132.485-1.3,0-2.228-.036-3.036-.054-.809-.036-1.455-.054-2.192-.054-.194,0-.593,0-1.024.018-.413,0-.88.036-1.24.036-.233,0-.359-.054-.359-.18,0-.089.071-.144.287-.144a2.952,2.952,0,0,0,.648-.071c.36-.072.45-.467.522-.989.089-.754.089-2.174.089-3.827V148.8c0-2.731,0-3.234-.036-3.809-.036-.61-.18-.9-.773-1.024a2.642,2.642,0,0,0-.593-.054c-.2,0-.305-.036-.305-.162s.126-.161.4-.161c.842,0,1.976.054,2.462.054.431,0,1.743-.054,2.443-.054.251,0,.377.036.377.161s-.108.162-.324.162a4.73,4.73,0,0,0-.7.054c-.485.09-.629.4-.665,1.024-.036.575-.036,1.078-.036,3.809Z" transform="translate(-564.05 -100.619)" fill="#fff"/>
            <path id="Caminho_1661" data-name="Caminho 1661" d="M796.66,149.885c0,1.725,0,3.073.089,3.827.054.518.162.917.7.989a7.623,7.623,0,0,0,.9.071c.234,0,.306.054.306.144,0,.126-.126.18-.4.18-1.132,0-2.264-.054-2.731-.054s-1.6.054-2.282.054c-.251,0-.377-.036-.377-.18,0-.089.072-.144.269-.144a2.95,2.95,0,0,0,.648-.071c.36-.072.449-.467.518-.989.09-.754.09-2.1.09-3.827v-3.161c0-2.731,0-3.234-.036-3.809-.036-.61-.18-.9-.773-1.024a3.666,3.666,0,0,0-.7-.054c-.179,0-.306-.036-.306-.144,0-.144.144-.179.413-.179.467,0,.988.018,1.455.018.467.018.881.036,1.132.036.683,0,4.33,0,4.744-.018a3.481,3.481,0,0,0,.736-.072c.108-.036.194-.108.288-.108a.145.145,0,0,1,.126.161c0,.126-.089.341-.144.809-.018.18-.054,1.24-.089,1.455-.018.144-.054.251-.18.251s-.161-.089-.179-.287a1.765,1.765,0,0,0-.108-.683,1.3,1.3,0,0,0-1.186-.539l-2.769-.054c-.107,0-.161.054-.161.251v4.492c0,.108.018.162.126.162l2.3-.016c.306,0,.557,0,.809-.018a.936.936,0,0,0,.772-.324c.09-.108.126-.18.234-.18.072,0,.108.054.108.162s-.089.557-.144,1.114c-.036.359-.072,1.186-.072,1.311,0,.162,0,.341-.179.341-.108,0-.144-.126-.144-.251a1.982,1.982,0,0,0-.108-.557,1.06,1.06,0,0,0-.916-.648c-.449-.054-2.354-.054-2.657-.054-.09,0-.126.036-.126.161Z" transform="translate(-719.341 -98.542)" fill="#fff"/>
            <path id="Caminho_1662" data-name="Caminho 1662" d="M929.116,146.731c-.09,0-.126.036-.161.144l-.972,2.569a3.493,3.493,0,0,0-.27,1.1c0,.324.162.557.719.557h.27c.216,0,.269.036.269.144,0,.144-.108.18-.305.18-.575,0-1.348-.054-1.9-.054-.194,0-1.186.054-2.12.054-.234,0-.341-.036-.341-.18,0-.108.072-.144.216-.144a5.387,5.387,0,0,0,.557-.036c.827-.108,1.166-.719,1.527-1.62l4.51-11.283c.216-.518.324-.719.5-.719.162,0,.27.161.45.575.431.988,3.3,8.318,4.455,11.05.683,1.62,1.2,1.869,1.582,1.959a3.937,3.937,0,0,0,.754.071c.144,0,.234.018.234.144,0,.144-.162.18-.827.18-.648,0-1.958,0-3.4-.036-.324-.018-.539-.018-.539-.144,0-.108.072-.144.252-.161a.293.293,0,0,0,.161-.413l-1.437-3.809a.173.173,0,0,0-.179-.126Zm3.665-.9c.089,0,.108-.054.089-.108l-1.617-4.438c-.018-.072-.036-.162-.09-.162s-.089.09-.108.162l-1.653,4.416c-.018.072,0,.126.072.126Z" transform="translate(-842.526 -94.867)" fill="#fff"/>
            <path id="Caminho_1663" data-name="Caminho 1663" d="M172.112,42.3l2.64,5.279-5.412.066Z" transform="translate(-136.484 -5.892)" fill="#d9a700"/>
            <path id="Caminho_1664" data-name="Caminho 1664" d="M65.462,36,55.3,56.4H75.691Zm0,.738,9.693,19.335H55.833Z" transform="translate(-29.833)" fill="#fff"/>
            <path id="Caminho_1665" data-name="Caminho 1665" d="M175,128.545l2.631-4.885H172.42Zm0-.177-2.452-4.629H177.5Z" transform="translate(-139.364 -81.98)" fill="#fff"/>
            <path id="Caminho_1666" data-name="Caminho 1666" d="M140.249,201.884,142.682,197H137.8Zm0-.177-2.321-4.629h4.626Z" transform="translate(-106.987 -150.568)" fill="#fff"/>
            <path id="Caminho_1667" data-name="Caminho 1667" d="M214.589,201.884,217.022,197H212.14Zm0-.177-2.32-4.629h4.626Z" transform="translate(-176.511 -150.568)" fill="#fff"/>
            <path id="Caminho_1668" data-name="Caminho 1668" d="M103.579,276.224l2.432-4.884H101.13Zm0-.176-2.32-4.63h4.625Z" transform="translate(-72.693 -220.091)" fill="#fff"/>
            <path id="Caminho_1669" data-name="Caminho 1669" d="M177.929,276.224l2.433-4.884H175.48Zm0-.176-2.321-4.63h4.626Z" transform="translate(-142.226 -220.091)" fill="#fff"/>
            <path id="Caminho_1670" data-name="Caminho 1670" d="M252.279,276.224l2.433-4.884H249.83Zm0-.176-2.321-4.63h4.626Z" transform="translate(-211.758 -220.091)" fill="#fff"/>
            <circle id="Elipse_67" data-name="Elipse 67" cx="0.264" cy="0.264" r="0.264" transform="translate(40.247 55.809)" fill="#fff"/>
            <circle id="Elipse_68" data-name="Elipse 68" cx="0.264" cy="0.264" r="0.264" transform="translate(35.431 55.809)" fill="#fff"/>
            <circle id="Elipse_69" data-name="Elipse 69" cx="0.264" cy="0.264" r="0.264" transform="translate(30.613 55.809)" fill="#fff"/>
            <circle id="Elipse_70" data-name="Elipse 70" cx="0.264" cy="0.264" r="0.264" transform="translate(33.055 50.992)" fill="#fff"/>
            <circle id="Elipse_71" data-name="Elipse 71" cx="0.264" cy="0.264" r="0.264" transform="translate(37.806 50.992)" fill="#fff"/>
            <circle id="Elipse_72" data-name="Elipse 72" cx="0.264" cy="0.264" r="0.264" transform="translate(42.557 50.992)" fill="#fff"/>
            <circle id="Elipse_73" data-name="Elipse 73" cx="0.264" cy="0.264" r="0.264" transform="translate(28.172 50.992)" fill="#fff"/>
            <circle id="Elipse_74" data-name="Elipse 74" cx="0.264" cy="0.264" r="0.264" transform="translate(35.364 46.175)" fill="#fff"/>
            <circle id="Elipse_75" data-name="Elipse 75" cx="0.264" cy="0.264" r="0.264" transform="translate(30.613 46.175)" fill="#fff"/>
            <circle id="Elipse_76" data-name="Elipse 76" cx="0.264" cy="0.264" r="0.264" transform="translate(40.116 46.175)" fill="#fff"/>
            <rect id="Retângulo_1020" data-name="Retângulo 1020" width="75.229" height="0.194" transform="translate(23.4 59.618)" fill="#fff"/>
            <path id="Caminho_1671" data-name="Caminho 1671" d="M266.187,437.158a2.13,2.13,0,0,1-.038-3.06,2.348,2.348,0,0,1,1.7-.57,5.3,5.3,0,0,1,.778.054c.2.027.376.075.538.091.059.005.081.032.081.065s-.016.108-.027.3-.005.473-.016.583c-.005.081-.016.124-.065.124s-.054-.043-.054-.119a.772.772,0,0,0-.21-.494,1.6,1.6,0,0,0-1.092-.334,1.4,1.4,0,0,0-1.011.334,2.01,2.01,0,0,0,1.2,3.307,1.166,1.166,0,0,0,.984-.3.907.907,0,0,0,.183-.355c.016-.086.027-.108.07-.108s.048.038.048.081a5.123,5.123,0,0,1-.118.713.19.19,0,0,1-.14.156,3.112,3.112,0,0,1-.968.124A2.689,2.689,0,0,1,266.187,437.158Z" transform="translate(-226.432 -371.771)" fill="#fff"/>
            <path id="Caminho_1672" data-name="Caminho 1672" d="M331.92,435.638a2.071,2.071,0,0,1,2.221-2.108,1.948,1.948,0,0,1,2.173,2.009,2.125,2.125,0,0,1-2.226,2.2A2.043,2.043,0,0,1,331.92,435.638Zm3.641.1c0-1.205-.694-1.952-1.576-1.952-.618,0-1.323.344-1.323,1.685,0,1.118.619,2.005,1.651,2.005C334.689,437.477,335.561,437.294,335.561,435.74Z" transform="translate(-288.529 -371.772)" fill="#fff"/>
            <path id="Caminho_1673" data-name="Caminho 1673" d="M408.272,436.705c.011.42.081.559.189.6a.854.854,0,0,0,.28.038c.059,0,.091.01.091.043s-.049.054-.124.054c-.35,0-.565-.016-.667-.016-.049,0-.3.016-.583.016-.07,0-.118-.005-.118-.054,0-.032.032-.043.086-.043a.961.961,0,0,0,.242-.027c.14-.043.161-.2.167-.667l.049-3.183c0-.108.016-.183.07-.183s.108.07.2.167c.065.07.882.947,1.667,1.726.365.365,1.091,1.14,1.182,1.226h.027l-.054-2.414c-.005-.328-.054-.43-.183-.484a.877.877,0,0,0-.285-.032c-.065,0-.086-.016-.086-.049s.059-.048.14-.048c.279,0,.538.016.648.016.06,0,.264-.016.527-.016.07,0,.124.005.124.048s-.032.049-.1.049a.583.583,0,0,0-.161.016c-.15.043-.194.156-.2.457l-.059,3.393c0,.119-.022.167-.07.167s-.124-.06-.183-.119c-.339-.324-1.028-1.027-1.587-1.581-.583-.583-1.183-1.253-1.28-1.355h-.016Z" transform="translate(-359.062 -371.538)" fill="#fff"/>
            <path id="Caminho_1674" data-name="Caminho 1674" d="M489.642,437.594c-.086-.038-.1-.065-.1-.183,0-.3.021-.618.027-.7s.021-.14.065-.14.054.049.054.091a1.157,1.157,0,0,0,.048.275.742.742,0,0,0,.769.543.654.654,0,0,0,.721-.613c0-.264-.08-.511-.527-.86l-.248-.194a1.6,1.6,0,0,1-.8-1.275c0-.592.494-1.016,1.242-1.016a2.609,2.609,0,0,1,.713.091c.048.01.075.027.075.065,0,.07-.021.226-.021.648,0,.119-.016.161-.06.161s-.053-.032-.053-.1a.653.653,0,0,0-.14-.355.679.679,0,0,0-.583-.264.566.566,0,0,0-.64.554c0,.247.124.435.57.774l.151.113c.648.49.882.861.882,1.372a1.085,1.085,0,0,1-.505.936,1.555,1.555,0,0,1-.855.22,1.861,1.861,0,0,1-.786-.143Z" transform="translate(-435.936 -371.76)" fill="#fff"/>
            <path id="Caminho_1675" data-name="Caminho 1675" d="M537.154,436.249c0-.817,0-.967-.01-1.14s-.065-.274-.183-.3a.76.76,0,0,0-.194-.021c-.054,0-.086-.01-.086-.054s.043-.043.13-.043c.2,0,.543.016.7.016.135,0,.454-.016.656-.016.07,0,.113.01.113.043s-.032.054-.086.054a.887.887,0,0,0-.161.016c-.145.027-.188.119-.2.306-.011.172-.011.324-.011,1.14v.947c0,.522,0,.947.022,1.178.016.145.054.242.215.263a2.211,2.211,0,0,0,.274.022c.059,0,.086.016.086.043s-.043.054-.1.054c-.355,0-.694-.016-.839-.016-.124,0-.463.016-.678.016-.069,0-.107-.016-.107-.054s.021-.043.086-.043a.861.861,0,0,0,.194-.022c.108-.021.14-.113.156-.269.027-.225.027-.648.027-1.172Z" transform="translate(-480.022 -372.857)" fill="#fff"/>
            <path id="Caminho_1676" data-name="Caminho 1676" d="M575.44,437.32c0,.145-.005.156-.069.194a3.047,3.047,0,0,1-1.3.242c-1.317,0-2.484-.624-2.484-2.108a2.011,2.011,0,0,1,.936-1.759,2.612,2.612,0,0,1,1.447-.349,6.522,6.522,0,0,1,.952.086,3.493,3.493,0,0,0,.424.06c.065.005.076.027.076.065a7.139,7.139,0,0,0-.038.882c0,.086-.016.113-.059.113s-.049-.027-.054-.07a.753.753,0,0,0-.183-.446,1.719,1.719,0,0,0-1.2-.42,1.552,1.552,0,0,0-1.065.334,1.6,1.6,0,0,0-.494,1.317,1.867,1.867,0,0,0,1.871,2.022,1.169,1.169,0,0,0,.454-.059.191.191,0,0,0,.1-.2v-.506c0-.269,0-.473-.006-.634-.005-.183-.054-.269-.231-.307a1.09,1.09,0,0,0-.21-.016c-.049,0-.081-.01-.081-.043s.038-.054.113-.054c.279,0,.618.016.778.016s.454-.016.629-.016c.07,0,.108.01.108.054s-.032.043-.07.043a.829.829,0,0,0-.145.016c-.145.027-.2.119-.2.307-.006.161-.006.376-.006.648Z" transform="translate(-512.67 -371.781)" fill="#fff"/>
            <path id="Caminho_1677" data-name="Caminho 1677" d="M645.392,436.705c.011.42.081.559.189.6a.852.852,0,0,0,.279.038c.06,0,.091.01.091.043s-.048.054-.123.054c-.35,0-.565-.016-.667-.016-.048,0-.3.016-.583.016-.07,0-.118-.005-.118-.054,0-.032.032-.043.086-.043a.963.963,0,0,0,.242-.027c.14-.043.161-.2.167-.667l.049-3.183c0-.108.016-.183.07-.183s.108.07.2.167c.065.07.882.947,1.667,1.726.366.365,1.092,1.14,1.183,1.226h.027l-.054-2.414c-.005-.328-.054-.43-.183-.484a.877.877,0,0,0-.285-.032c-.065,0-.086-.016-.086-.049s.059-.048.14-.048c.279,0,.538.016.648.016.059,0,.264-.016.527-.016.07,0,.124.005.124.048s-.032.049-.1.049a.583.583,0,0,0-.161.016c-.151.043-.194.156-.2.457l-.059,3.393c0,.119-.022.167-.07.167s-.124-.06-.183-.119c-.338-.324-1.027-1.027-1.586-1.581-.583-.583-1.183-1.253-1.28-1.355h-.016Z" transform="translate(-580.818 -371.538)" fill="#fff"/>
            <path id="Caminho_1678" data-name="Caminho 1678" d="M715.322,435.64c-.027,0-.038.01-.049.043l-.29.769a1.048,1.048,0,0,0-.081.328c0,.1.049.167.215.167h.081c.065,0,.08.01.08.043s-.032.054-.091.054c-.172,0-.4-.016-.57-.016-.059,0-.354.016-.634.016-.07,0-.1-.011-.1-.054s.021-.043.065-.043.124-.006.167-.011c.247-.032.35-.215.457-.484l1.35-3.377c.065-.156.1-.215.15-.215s.081.049.135.172c.13.3.989,2.489,1.333,3.3.2.484.36.559.473.583a1.179,1.179,0,0,0,.226.022c.043,0,.07.005.07.043s-.049.054-.248.054-.583,0-1.017-.011c-.1-.005-.161-.005-.161-.043s.021-.043.075-.049a.087.087,0,0,0,.049-.124l-.43-1.14a.051.051,0,0,0-.054-.038Zm1.1-.269c.027,0,.032-.016.027-.032l-.484-1.328c-.005-.021-.011-.048-.026-.048s-.027.027-.032.048l-.5,1.321c-.005.021,0,.038.022.038Z" transform="translate(-645.74 -371.145)" fill="#fff"/>
            <path id="Caminho_1679" data-name="Caminho 1679" d="M783.064,436.249c0-.817,0-.967-.011-1.14s-.054-.269-.231-.306a1.083,1.083,0,0,0-.21-.016c-.059,0-.091-.01-.091-.049s.037-.048.119-.048c.285,0,.624.016.774.016.167,0,.505-.016.828-.016a2.845,2.845,0,0,1,2.156.613,1.919,1.919,0,0,1,.518,1.366,2.138,2.138,0,0,1-.583,1.5,2.453,2.453,0,0,1-1.8.635c-.194,0-.414-.016-.618-.032s-.393-.032-.527-.032c-.059,0-.177,0-.307.005s-.263.011-.371.011c-.07,0-.108-.016-.108-.054s.021-.043.086-.043a.851.851,0,0,0,.194-.022c.107-.021.135-.139.156-.3.027-.226.027-.648.027-1.146Zm.672.592c0,.57.005.984.011,1.087a1.162,1.162,0,0,0,.059.409c.07.1.279.215.7.215a1.721,1.721,0,0,0,1.237-.389,1.7,1.7,0,0,0,.454-1.339,1.82,1.82,0,0,0-.518-1.344,2.129,2.129,0,0,0-1.527-.538,1.435,1.435,0,0,0-.349.038.1.1,0,0,0-.065.108c-.005.183-.005.648-.005,1.076Z" transform="translate(-709.933 -372.857)" fill="#fff"/>
            <path id="Caminho_1680" data-name="Caminho 1680" d="M859.55,435.638a2.07,2.07,0,0,1,2.221-2.108,1.948,1.948,0,0,1,2.173,2.009,2.125,2.125,0,0,1-2.226,2.2A2.043,2.043,0,0,1,859.55,435.638Zm3.64.1c0-1.205-.693-1.952-1.575-1.952-.619,0-1.323.344-1.323,1.685,0,1.118.618,2.005,1.651,2.005C862.319,437.477,863.19,437.294,863.19,435.74Z" transform="translate(-781.971 -371.772)" fill="#fff"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'IconConsignadoLogoWhite'
}
</script>

<style>

</style>