<template>
  <div class="a-button-chat">
    <transition name="slide-fade-label">
      <label v-if="principal && showLabel && label" class="a-button-chat__social-media">{{ label }}</label>
    </transition>
    <template v-if="link">
      <a :href="link" class="a-button-chat__link" :target="blank" @click="$emit('action-chat')">
        <img class="a-button-chat__img" :class="{ 'a-button-chat__img--big': big }"
          src="@/assets/img/ico-chat-whatsapp.svg" :alt="label">
      </a>
    </template>
  </div>
</template>

<script>

export default {
  name: 'ButtonChat',
  props: {
    img: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    blank: {
      type: Boolean,
    },
    big: {
      type: Boolean,
    },
    principal: {
      type: Boolean,
    },
    secundario: {
      type: Boolean,
    },
  },
  data() {
    return {
      showLabel: false,
    };
  },
  methods: {
    actionChat() {
      this.$emit('action-chat');
      this.showLabel = !this.showLabel;
    },
  },
};
</script>

<style lang="scss" scoped>
.a-button-chat {
  align-items: center;
  display: flex;
  z-index: 20;

  &__social-media {
    background-color: $alfa-sec;
    border-radius: 3px;
    box-shadow: 4px 4px 20px rgb(0, 0, 0, 0.4);
    color: $alfa-default-text;
    font-family: $montserrat-semi-bold;
    font-size: 0.875rem;
    line-height: 1.625rem;
    padding: 2px 10px;
    text-transform: capitalize;
    margin-right: 20px;

    @media (max-width: $tablet) {
      font-size: 1rem;
    }
  }

  &__link {
    cursor: pointer;

    &--button {
      background-color: transparent;
      border: 0;
      margin: 0;
      padding: 0;
    }
  }

  &__img {
    filter: drop-shadow(4px 4px 10px rgb(0, 0, 0, 0.4));
    height: 55px;
    max-width: 55px;

    @media (min-width: 1280px) {
      height: 80px;
      max-width: 80px;
    }

    &--big {
      height: 65px;
      max-width: 65px;
      margin-right: -5px;

      @media (min-width: 1280px) {
        height: 90px;
        max-width: 90px;
      }
    }
  }
}

.slide-fade-label-enter-active {
  transition: all 0.1s ease;
}

.slide-fade-label-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-label-enter,
.slide-fade-label-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
