<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="25.203" height="31.917" viewBox="0 0 25.203 31.917">
    <g id="icon_contrato" transform="translate(-38.2)">
        <path id="Caminho_1891" data-name="Caminho 1891" d="M63.3,7.03c-.035-.035-.035-.07-.07-.105L57.252.211h0c-.035-.035-.105-.07-.141-.105-.035,0-.035-.035-.07-.035A.652.652,0,0,0,56.795,0H42.137A3.93,3.93,0,0,0,38.2,3.937V27.98a3.93,3.93,0,0,0,3.937,3.937H59.466A3.93,3.93,0,0,0,63.4,27.98V7.346c0-.07-.035-.176-.035-.246C63.333,7.065,63.333,7.03,63.3,7.03Zm-5.87-4.675,3.867,4.323H57.427ZM62.1,27.945a2.6,2.6,0,0,1-2.6,2.6H42.137a2.6,2.6,0,0,1-2.6-2.6V3.9a2.6,2.6,0,0,1,2.6-2.6h13.99V7.346a.667.667,0,0,0,.668.668H62.1Z" fill="#1fbccd"/>
        <path id="Caminho_1892" data-name="Caminho 1892" d="M90.668,76h-5.8a.667.667,0,0,0-.668.668.646.646,0,0,0,.668.668h5.8a.668.668,0,0,0,0-1.336Z" transform="translate(-41.958 -69.321)" :fill="fill"/>
        <path id="Caminho_1893" data-name="Caminho 1893" d="M90.668,272h-5.8a.668.668,0,1,0,0,1.336h5.8a.667.667,0,0,0,.668-.668A.718.718,0,0,0,90.668,272Z" transform="translate(-41.958 -248.098)" :fill="fill"/>
        <path id="Caminho_1894" data-name="Caminho 1894" d="M100.734,125.6H85.268a.668.668,0,0,0,0,1.336h15.466a.667.667,0,0,0,.668-.668A.718.718,0,0,0,100.734,125.6Z" transform="translate(-42.323 -114.563)" :fill="fill"/>
        <path id="Caminho_1895" data-name="Caminho 1895" d="M100.734,162.4H85.268a.668.668,0,1,0,0,1.336h15.466a.667.667,0,0,0,.668-.668A.718.718,0,0,0,100.734,162.4Z" transform="translate(-42.323 -148.129)" :fill="fill"/>
        <path id="Caminho_1896" data-name="Caminho 1896" d="M100.734,199.2H85.268a.668.668,0,0,0,0,1.336h15.466a.667.667,0,0,0,.668-.668A.718.718,0,0,0,100.734,199.2Z" transform="translate(-42.323 -181.695)" :fill="fill"/>
        <path id="Caminho_1897" data-name="Caminho 1897" d="M100.734,236H85.268a.668.668,0,1,0,0,1.336h15.466a.667.667,0,0,0,.668-.668A.718.718,0,0,0,100.734,236Z" transform="translate(-42.322 -215.261)" :fill="fill"/>
    </g>
    </svg>
</template>

<script>
export default {
    name: 'IconContract',
    props: {
        fill: {
            type: String,
            default: '#00c5d7'
        }
    }
}
</script>
