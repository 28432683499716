<template>
  <aside class="a-menu" :class="{ fullw: show }">
    <div class="a-menu__actions">
      <img class="a-menu__actions__icon" src="@/assets/img/home/logo.png" alt="Alfa Consignado" />

      <!-- <AtomLogo data-cy="logo" text="minha conta" class="a-menu__logo" /> -->
      <div class="a-menu__close">
        <button class="a-menu__close--button" @click="handleClosed">
          <IconClose fill="#00305a" width="35" height="35" />
          <TextTypography class="a-menu__close__text" tag="text-support">Fechar</TextTypography>
        </button>
      </div>
    </div>

    <nav class="a-menu__list">
      <p class="a-menu__list__pagelink" v-for="(link, index) in links" :key="index"
        @click.prevent="handleLocation(link.href)">
        <IconHome v-if="link.icon === 'home'"></IconHome>
        <IconStar v-else-if="link.icon === 'star'"></IconStar>
        <IconProfile v-else-if="link.icon === 'profile'"></IconProfile>
        <IconContract v-else-if="link.icon === 'contract'"></IconContract>
        <IconHelp v-else></IconHelp>
        {{ link.name }}
      </p>

      <p 
        class="a-menu__list__pagelink a-menu__list__pagelink--last"
        @click.prevent="handleLocation('https://alfaemprestimopessoal.com.br/simulador-online')"
      >
        <!-- verificar qual caminho(path) corrreto para passar aqui nesta função -->
        <IconExit fill="#002a58" />
        Empréstimo Pessoal
      </p>

      <slot />
    </nav>
  </aside>
</template>

<script>
import IconClose from '@/assets/icons/IconClose'
import ButtonDefault from '~/components/shared/buttons/ButtonDefault/ButtonDefault.vue'
import TextTypography from '~/components/shared/typography/TextTypography.vue'

export default {
  name: 'AtomMenu',
  components: {
    IconClose,
    ButtonDefault,
    TextTypography,
  },
  props: {
    outshow: {
      // recebe o click do botão fora do atomo
      type: Boolean,
      default: false,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  watch: {
    outshow() {
      this.show = this.outshow
    },
  },
  mounted() {
    this.show = this.outshow
  },
  methods: {
    handleClosed() {
      this.show = false
      this.$emit('closed', false)
      document.body.style.overflow = ''
      document.body.style.transform = ''
    },
    handleLocation(location) {
      window.location.href = location
    },
    handleLogin() {
      // window.location.href = `${process.env.externallinks}/login`;
      window.location.href = `${process.env.absolutePath}/minha-conta`
    },
  },
}
</script>

<style lang="scss" scoped>
.a-menu {
  background-color: white;
  color: $alfa-primary;
  width: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  transition: all 1s ease;
  z-index: 30;
  left: 100%;

  @media (min-width: $tablet) {
    left: -21rem;
    width: 21rem;
  }

  &.fullw {
    left: 0;
    overflow-y: auto;
    max-width: 100%;
  }

  &__close {
    width: 100%;
    justify-content: space-between;
    display: none;

    @media (max-width: $tablet) {
      display: flex;
    }

    &--button {
      border: 0;
      background-color: transparent;
      color: white;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      row-gap: $spacing_8;
      /* font-weight: bold; */
      margin: auto 0 auto auto;

      svg {
        margin: 0 auto;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__text {
      color: white;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: $spacing_16 $spacing_32;
    align-items: center;
    background-color: $alfa-pri;

    @media (min-width: $tablet) {
      display: none;
      align-items: center;
    }

    &__icon {
      width: auto;
      height: 95px;

      @media (max-width: 1600px) {
        height: 78px;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: calc(100vh - 104px);

    &__pagelink {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 2rem;
      border-bottom: 1px solid $alfa-apoio-3;
      font-family: $montserrat-regular;
      font-size: 18px;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      &--last {
        border: none;
      }
    }

    .a-button--normal {
      width: 100%;
      margin: 1rem 0;
    }
  }
}

.pelicula {
  position: absolute;
  width: 100%;
  background-color: #00000045;
  z-index: 1;
  height: 100vh;
  transition: all 1s ease;
}
</style>
