<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="23.099" height="23.099" viewBox="0 0 23.099 23.099">
        <g id="_--icon_saida" data-name="--icon_saida" transform="translate(-913.399 -190)">
            <path id="Caminho_1756" data-name="Caminho 1756" d="M131.534,103.772h6.375a1.024,1.024,0,1,0,0-2.048h-6.375a3.551,3.551,0,0,0-3.546,3.54l-.021,13.711a3.546,3.546,0,0,0,3.54,3.551l13.732.021h.005A3.546,3.546,0,0,0,148.79,119v-6.4a1.024,1.024,0,0,0-2.048,0V119a1.5,1.5,0,0,1-1.5,1.5h0l-13.732-.021a1.5,1.5,0,0,1-1.5-1.5l.021-13.711a1.5,1.5,0,0,1,1.5-1.5Z" transform="translate(785.431 90.552)" :fill="fill"/>
            <path id="Caminho_1757" data-name="Caminho 1757" d="M331.489,65.013a1.024,1.024,0,0,0,1.024-1.024v-5a1.024,1.024,0,0,0-1.024-1.024h-5a1.024,1.024,0,1,0,0,2.048H328.9l-9.234,9.234a1.024,1.024,0,0,0,1.448,1.448l9.347-9.347v2.639a1.024,1.024,0,0,0,1.024,1.024Z" transform="translate(603.985 132.034)" :fill="fill"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'IconExit',
    props: {
        fill: {
            type: String,
            default: '#ffb800'
        }
    }
}
</script>

<style>

</style>