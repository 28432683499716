<template>
  <div data-cy="botao-chat" class="m-chat">
    <ButtonChat class="m-chat__button" />
    <transition name="slide-fade-2">
      <ButtonChat class="m-chat__button" label="WhatsApp"
        link="https://api.whatsapp.com/send/?phone=551133868659&text&app_absent=0" blank :secundario="whatsappButton" />
    </transition>
    <div class="m-chat__overlay" @click="showButtons" />
  </div>
</template>

<script>
import ButtonChat from './ButtonChat.vue';
export default {
  name: 'MoleculeChat',
  components: { ButtonChat },

  data() {
    return {
      chatButton: true,
      whatsappButton: true,
    };
  },
};
</script>

<style lang="scss">
.m-chat {
  align-items: flex-end;
  flex-direction: column-reverse;
  display: flex;
  transition: all 0.5s;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;

  &--open {
    width: 100%;
  }

  &__overlay {
    cursor: pointer;
    height: 100vh;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
    position: fixed;
    transition: all 0.3s;
    // opacity: 0;
    visibility: hidden;

    &--open {
      opacity: 0.5;
      visibility: visible;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.2, 0.4, 1);
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }

  .slide-fade-2-enter-active {
    transition: all 0.1s ease;
  }

  .slide-fade-2-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.3, 0.5, 1);
  }

  .slide-fade-2-enter,
  .slide-fade-2-leave-to {
    transform: translateY(10px);
    // opacity: 0;
  }

  .slide-fade-3-enter-active {
    transition: all 0.5s ease;
  }

  .slide-fade-3-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.3, 0.5, 1);
  }

  .slide-fade-3-enter,
  .slide-fade-3-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }
}
</style>
