<template>
  <!-- <svg id="icon_fechar" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
		<path id="Caminho_425" data-name="Caminho 425" d="M10.5,19.688A9.188,9.188,0,1,0,1.313,10.5,9.187,9.187,0,0,0,10.5,19.688ZM10.5,21A10.5,10.5,0,1,0,0,10.5,10.5,10.5,0,0,0,10.5,21Z" fill="#ffffff" />
		<path id="Caminho_426" data-name="Caminho 426" d="M19.3,9.191a.656.656,0,0,1,0,.929l-9.18,9.18a.657.657,0,0,1-.929-.929l9.18-9.18a.656.656,0,0,1,.929,0Z" transform="translate(-3.745 -3.745)" fill="#a7b9c9" />
		<path id="Caminho_427" data-name="Caminho 427" d="M9.191,9.191a.656.656,0,0,0,0,.929l9.18,9.18a.657.657,0,0,0,.929-.929l-9.18-9.18a.656.656,0,0,0-.929,0Z" transform="translate(-3.745 -3.745)" fill="#a7b9c9" />
	</svg> -->
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height" viewBox="0 0 55 55">
    <defs>
      <filter
        id="Elipse_96"
        x="0"
        y="0"
        width="55"
        height="55"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="2" result="blur" />
        <feFlood flood-color="#004682" flood-opacity="0.2" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="icon_fechar" transform="translate(-325 -23)">
      <g transform="matrix(1, 0, 0, 1, 325, 23)" filter="url(#Elipse_96)">
        <circle
          id="Elipse_96-2"
          data-name="Elipse 96"
          cx="21.5"
          cy="21.5"
          r="21.5"
          transform="translate(6 3)"
          fill="#00305a"
        />
      </g>
      <path
        id="Caminho_96"
        data-name="Caminho 96"
        d="M0,0H20"
        transform="translate(345 40) rotate(45)"
        fill="#f3f4f4"
        stroke="#f3f4f4"
        stroke-linecap="round"
        stroke-width="3"
      />
      <path
        id="Caminho_97"
        data-name="Caminho 97"
        d="M20,0H0"
        transform="translate(359.143 40) rotate(135)"
        fill="#f3f4f4"
        stroke="#f3f4f4"
        stroke-linecap="round"
        stroke-width="3"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'IconClose',
  props: {
    width: {
      type: String,
      default: '55'
    },
    height: {
      type: String,
      default: '55'
    }
  }

}
</script>
