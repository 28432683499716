<template>
  <div class="l-default">
    <Head>
      <Title>
        {{ pageMeta.title }} | Alfa Empréstimo Consignado
      </Title>
      <Meta name="description" :content="pageMeta.description" />
      <Meta name="keywords" :content="pageMeta.keywords" />
    </Head>
    <FlexHeader :path-links="pathLinks" />
    <JunctionButtonChatVue />
    <slot />
    <Footer />
    <AtomInfoModal />
    <OrganismCookieManager v-if="!hideModalCookieManager"/>
  </div>
</template>

<script>
// Composables
import useSessionStorage from '~/composables/useSessionStorage'

import { ref, onMounted } from 'vue';

// Componentes
import FlexHeader from '@/components/shared/header/FlexHeader.vue'
import Footer from '@/components/shared/footer/Footer.vue'
import JunctionButtonChatVue from '~/components/shared/ButtonChat/JunctionButtonChat.vue'
import AtomInfoModal from '@/components/institutional/atoms/AtomInfoModal/AtomInfoModal.vue'
import OrganismCookieManager from '~/components/institutional/organisms/OrganismCookieManager/OrganismCookieManager.vue'

export default {
  name: 'LayoutDefault',
  components: {
    FlexHeader,
    Footer,
    JunctionButtonChatVue,
    AtomInfoModal,
    OrganismCookieManager
  },
  setup() {
    const hideModalCookieManager = ref(false);
    // Verifique se a meta da página atual especifica para esconder o modal
    const currentRouteMeta = useRoute().meta;
    if (currentRouteMeta.hideCookiesModal) {
      hideModalCookieManager.value = true;
    }
    
    return {
      hideModalCookieManager,
    };
  },
  data() {
    return {
      pathLinks: [
        { name: 'Início', href: '/', icon: 'home' },
        { name: 'Vantagens e Diferenciais', href: '/vantagens-e-diferenciais', icon: 'star' },
        { name: 'Quem Somos', href: '/quem-somos', icon: 'profile' },
        { name: 'Blog', href: '/blog', icon: 'contract' },
        { name: 'Ajuda', href: '/ajuda', icon: 'help' },
      ],
    }
  },
  computed: {
    pageMeta() {
      return this.$route.meta
    },
  },
  mounted() {
    // Route
    const queryString = this.$route.query
    // Composable
    const sessionStorage = useSessionStorage()
    // Lógica
    sessionStorage.setItem('campaign_utms', queryString)
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.l-default {
  // display: grid;
  // grid-template-rows: auto 1fr auto;
  // place-items: start stretch;
  // place-content: start stretch;
  width: 100%;
  position: relative;
}
</style>
